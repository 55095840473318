<template>
  <v-col>
    <v-card>
      <v-card-actions>
        <v-row>
          <v-col>
            <span class="title" style="color: red;">{{ config.name }}</span>
          </v-col>
          <v-col>
            <span class="title">Resumo Diário de Situação - RDS</span>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="dialogFilter = true" class="ml-2" color="primary" outlined rounded>
              <v-icon>{{ icons.mdiFilter }}</v-icon>Filtro
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small outlined large class="ml-2" color="primary"
                  @click="sendExportPdf()">
                  <v-icon>{{ icons.mdiPrinter }}</v-icon>
                </v-btn>
              </template>
              <span>Imprimir</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-card class="mt-2">
      <v-card-actions>
        <v-row class="flex-row-reverse ">
          <span class="text-body-1" >DATA: {{ formatDate(filter.date )}}</span>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
    <RdsResultHosting v-else :rds="rds" />

    <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
    <RdsResultProducs v-else :rds="rds" />
    
    <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
    <RdsResultService v-else :rds="rds" />
    
    <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
    <RdsResultPayment v-else :rds="rds" />

    <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
    <RdsResultTotal v-else :rds="rds" />

    <v-skeleton-loader v-if="loading" class="mt-2" type="card"></v-skeleton-loader>
    <RdsStatistcs v-else :rds="rds" />




    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="Filtro" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field v-model="filter.date" :error-messages="error.date" @change="error.date = null"
                        outlined label="Data" type="date"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="setToday()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn :loading="loadingFilter" :disabled="loadingFilter" @click="confirmFilter()" color="primary"
                class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>

</template>





<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import MsgDelete from '@/components/MsgDelete.vue'
import { mdiClose, mdiTools, mdiFileExcelOutline, mdiFilter, mdiPrinter, mdiFilePdf, mdiFilePdfOutline } from '@mdi/js'
import {
  mapActions
} from 'vuex'
import RdsResultHosting from './_components/RdsResultHosting.vue'
import RdsResultProducs from './_components/RdsResultProducs.vue'
import RdsResultService from './_components/RdsResultService.vue'
import RdsResultPayment from './_components/RdsResultPayment.vue'
import RdsResultTotal from './_components/RdsResultTotal.vue'
import RdsStatistcs from './_components/RdsStatistcs.vue'



export default {
  components: {
    BarTitleDialog,
    MsgDelete,
    RdsResultHosting,
    RdsResultProducs,
    RdsResultService,
    RdsResultPayment,
    RdsResultTotal,
    RdsStatistcs

  },
  data: () => ({
    loading: false,
    config: {},
    dialogFilter: false,
    error: {
      date: null
    },
    filter: {
      date: null
    },
    loadingFilter: false,
    rds: {},

    icons: {
      mdiClose,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
      mdiPrinter,
      mdiFilePdf,
      mdiFilePdfOutline
    }

  }),

  computed: {},
  watch: {},

  created() {
    this.setToday();
    this.initialize();
    this.loadConfig().then((response) => {
      this.config = response.data
    })
  },

  methods: {
    ...mapActions('rds', ['get','exportPdf']),
    ...mapActions('config', ['loadConfig']),

    initialize() {
      this.loading = true
      this.get(this.filter).then((response) => {
        this.rds = response.data

      }).finally(() => {
        this.loadingFilter = false
        this.loading = false
      })


    },
    confirmFilter() {
      this.loadingFilter = true
      this.dialogFilter = false
      this.initialize()
    },

    showFilterDialog() {
      this.dialogFilter = true
      console.log(this.filter.date)
    },

    setToday() {
      let today = new Date()
      today.setHours(today.getHours() - 3);
      today.setDate(today.getDate() - 1);
      this.filter.date = today.toISOString().substring(0, 10)
    },

    formatDate(date) {
      if (date) {
        let [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      }
    },
    sendExportPdf() {
      this.exportPdf(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .finally()
    },


  }


}
</script>
